dmx.Component('bs4-collapse', {

  initialData: {
    collapsed: true
  },

  attributes: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle () {
      jQuery(this.$node).collapse('toggle');
    },

    show () {
      jQuery(this.$node).collapse('show');
    },

    hide () {
      jQuery(this.$node).collapse('hide');
    },
  },

  events: {
    show: Event,
    shown: Event,
    hide: Event,
    hidden: Event,
  },

  render (node) {
    this.$parse();

    jQuery(node).on('show.bs.collapse', this.dispatchEvent.bind(this, 'show'));
    jQuery(node).on('shown.bs.collapse', this.dispatchEvent.bind(this, 'shown'));
    jQuery(node).on('hide.bs.collapse', this.dispatchEvent.bind(this, 'hide'));
    jQuery(node).on('hidden.bs.collapse', this.dispatchEvent.bind(this, 'hidden'));
    jQuery(node).on('shown.bs.collapse', this._shownHandler.bind(this));
    jQuery(node).on('hidden.bs.collapse', this._hiddenHandler.bind(this));
    jQuery(node).addClass('collapse');
    jQuery(node).toggleClass('show', this.props.show);
    jQuery(node).collapse({ toggle: false });
  },

  performUpdate (props) {
    if (props.show != this.props.show) {
      jQuery(this.$node).toggleClass('show', this.props.show);
      this.set('collapsed', !this.props.show);
    }
  },

  destroy () {
    jQuery(this.$node).off('.bs.collapse');
    jQuery(this.$node).collapse('dispose');
  },

  _shownHandler () {
    this.set('collapsed', false);
  },

  _hiddenHandler () {
    this.set('collapsed', true);
  },

});
